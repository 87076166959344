body {
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('./fonts/TitilliumWeb-Regular.woff') format('woff');
}

code {
  font-family: "Titillium Web", sans-serif;
}
